.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

.qrcode-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.qrcode {
  width: 500px;
  height: 500px;
}

.logo {
  position: absolute;
  top: 211px;
  left: 211px;
  width: 80px;
  height: 80px;
  /* display: none; */
}

.time {
  margin-bottom: 20px;
  font-size: 32px;
}

.terminal {
  position: absolute;
  top: 5px;
  left: 5px;
}

button[type="button"] {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #069;
  cursor: pointer;
}

.powered {
  height: 20px;
}
