.offline-message {
  position: absolute;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  background-color: rgb(255, 0, 0);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  animation: scale 1.5s alternate infinite ease-in;
}

@keyframes scale {
  0%   {transform: scale(1);}
  100% {transform: scale(1.1);}
}
